import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { white } from '@salutejs/plasma-tokens-b2c';
import { Trans, useTranslation } from 'next-i18next';

import { desktopSize, isDesktop, media } from '../../const/tokens';
import { useMouseWheel } from '../../hooks/useMouseWheel';
import { Title } from '../../components/Title/Title';
import { Text } from '../../components/Text/Text';
import { AdaptiveButton } from '../../components/Button/AdaptiveButton';
import { Header } from '../../components/Landing/Header';
import { ApiDataContext } from '../../store/ApiDataContext/ApiDataContext';
import { LayoutContext } from '../../store/LayoutContext';
import { useLanguage } from '../../hooks/useLocale';

import { Character3DPreview } from './Character3DPreview';
import { WheelDirection } from './typings';
import { scrollToSignUp } from './utils/signUp';
import { trackLandingLogin } from './utils/LandingAnalytics';

const StyledContainer = styled.section`
    height: 100vh;
    background: #000;
    display: flex;
    flex-flow: column;

    ${media.tablet} {
        height: auto;
    }

    ${media.mobile} {
        height: auto;
    }
`;

const StyledContent = styled.div`
    position: relative;
    display: flex;
    flex-flow: row;
    width: 100%;
    max-width: ${desktopSize}px;
    margin: 0 auto;
    height: 100%;

    ${media.tablet} {
        padding-top: 69px;
        padding-right: 25px;
    }

    ${media.mobile} {
        flex-flow: column-reverse;
        padding: 0 24px;
    }
`;

const StyledDescriptionBlock = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    max-width: 774px;
    margin-left: auto;
    justify-content: center;

    ${media.tablet} {
        justify-content: normal;
        padding-top: 11px;
    }

    ${media.mobile} {
        justify-content: normal;
        padding-top: 32px;
        margin-bottom: 11px;
    }
`;

const StyledTitle = styled(Title)`
    color: ${white};
    margin-bottom: 16px;
    margin-top: -56px;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.01em;
    font-family: 'SB Sans Display', sans-serif;

    ${media.tablet} {
        font-size: 40px;
        line-height: 48px;
        margin-top: 0;
    }

    ${media.mobile} {
        font-size: 27px;
        line-height: 32px;
        margin-top: 0;
    }
`;

const StyledDescription = styled(Text)`
    color: ${white};
    max-width: 739px;

    ${media.mobile} {
        margin-bottom: 24px;
    }
`;

const StyledTryItButton = styled(AdaptiveButton)`
    margin-top: 32px;
    width: 226px;

    ${media.mobile} {
        margin-top: 0;
        width: auto;
    }
`;

export const PreviewSection: React.FC = () => {
    const { t } = useTranslation('index');
    const {
        fetchers: { fetchTariffs },
    } = useContext(ApiDataContext);
    const lang = useLanguage();
    const { tariffsModal } = useContext(LayoutContext);

    const wheelEventOptions = useMemo(() => ({ passive: false }), []);
    const scrollTimeoutRef = useRef<number>();

    const scrollTo = useCallback((event, target: number) => {
        // прерывание события в FF ломает браузерную автоподкрутку
        // чтобы не писать свою автоподкрутку подскролливание в этом блоке отключено для FF
        if (/firefox/i.test(navigator.userAgent)) {
            return;
        }

        if (scrollTimeoutRef.current) {
            // @ts-ignore
            clearTimeout(scrollTimeoutRef.current);
        } else {
            window.scrollTo({ top: target, behavior: 'smooth' });
        }

        event.preventDefault();
        // @ts-ignore
        scrollTimeoutRef.current = setTimeout(() => {
            // @ts-ignore
            scrollTimeoutRef.current = undefined;
        }, 50);
    }, []);

    const mousewheel = useCallback(
        (event) => {
            const { scrollTop } = document.getElementsByTagName('html')[0];
            const delay = (event as WheelEvent).deltaY;
            const direction = delay < 0 ? WheelDirection.up : WheelDirection.down;
            const windowHeight = window.innerHeight;
            const downScrollEndPositionRate = 1.8;
            const upScrollStartBorderRate = 1.7;

            if (!isDesktop() || tariffsModal.isOpen) {
                return;
            }

            if (direction === WheelDirection.down && scrollTop < windowHeight) {
                scrollTo(event, windowHeight * downScrollEndPositionRate);
            }

            if (direction === WheelDirection.up && scrollTop < windowHeight * upScrollStartBorderRate) {
                scrollTo(event, 0);
            }
        },
        [scrollTo, tariffsModal.isOpen],
    );

    useMouseWheel(mousewheel, wheelEventOptions);

    useEffect(() => {
        fetchTariffs(lang);
    }, [lang, fetchTariffs]);

    return (
        <StyledContainer id="landingPreview">
            <Header onLogin={trackLandingLogin} onTariffsClick={tariffsModal.open} />
            <StyledContent>
                <Character3DPreview />
                <StyledDescriptionBlock>
                    <StyledTitle>
                        <Trans t={t} i18nKey="Превращайте текст в эффектное видео">
                            Превращайте текст <br />в эффектное видео
                        </Trans>
                    </StyledTitle>
                    <StyledDescription>{t('landingDesc')}</StyledDescription>
                    <StyledTryItButton onClick={scrollToSignUp} data-e2e="tryItButton">
                        {t('Попробовать')}
                    </StyledTryItButton>
                </StyledDescriptionBlock>
            </StyledContent>
        </StyledContainer>
    );
};

import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { isDesktop, media } from '../../const/tokens';
import { useScroll } from '../../hooks/useScroll';
import { useMouseWheel } from '../../hooks/useMouseWheel';
import { useWindowResize } from '../../hooks/useWindowResize';

import { WheelDirection } from './typings';

interface StyledContainerProps {
    height: number;
}

const StyledContainer = styled.div<StyledContainerProps>`
    position: relative;
    height: ${({ height }) => height}vh;
    width: 100vw;

    ${media.tablet} {
        height: auto;
    }

    ${media.mobile} {
        height: auto;
    }
`;

interface StyledFixedWrapProps {
    fixed: boolean;
    bottom: boolean;
}

const StyledFixedWrap = styled.div<StyledFixedWrapProps>`
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    top: ${({ bottom }) => (bottom ? 'calc(100% - 100vh)' : '0')};

    ${({ fixed }) =>
        fixed &&
        css`
            position: fixed;
            top: 0;
            left: 0;
        `}

    ${media.tablet} {
        position: relative;
        width: auto;
        height: auto;
        left: auto;
        top: auto;
    }

    ${media.mobile} {
        position: relative;
        width: auto;
        height: auto;
        left: auto;
        top: auto;
    }
`;

export const HorizontalScrollContainer: React.FC = ({ children }) => {
    const [progress, setProgress] = useState<number>(0);
    const progressStart = 0;
    const progressEnd = 100;
    const wheelEventOptions = useMemo(() => ({ passive: false }), []);
    const scrollTimeoutRef = useRef<number>();
    const containerRef = useRef<HTMLDivElement>(null);
    const elements = useMemo(
        () => React.Children.toArray(children).filter((child) => typeof child === 'object' && !Array.isArray(child)),
        [children],
    );

    const getElementProgress = useCallback(
        (index: number): number => {
            const elementPart = progressEnd / elements.length;
            const elementProgressPosition = elementPart * index;

            if (progress < elementProgressPosition) {
                return progressStart;
            }

            if (progress >= elementProgressPosition + elementPart) {
                return progressEnd;
            }

            return ((progress - elementProgressPosition) / elementPart) * progressEnd;
        },
        [elements.length, progress],
    );

    const getCurrentProgress = useCallback((): number => {
        if (!isDesktop()) {
            return progressEnd;
        }

        const { scrollTop } = document.getElementsByTagName('html')[0];
        const windowHeight = window.innerHeight;
        const scrollHeight = windowHeight * elements.length;
        let currentProgress = ((scrollTop - windowHeight) / (scrollHeight - windowHeight)) * progressEnd;

        if (currentProgress <= progressStart) {
            currentProgress = progressStart;
        } else if (currentProgress >= progressEnd) {
            currentProgress = progressEnd;
        }

        return currentProgress;
    }, [elements.length]);

    const resize = useCallback(() => setProgress(getCurrentProgress()), [getCurrentProgress]);

    const scrollToNearestBlock = useCallback(
        (lastDirection: WheelDirection) => () => {
            const windowHeight = window.innerHeight;
            const scrollHeight = windowHeight * elements.length;
            const oneBlockPercent = progressEnd / elements.length;
            const currentProgress = getCurrentProgress();
            let currentBlockIndex = Math.round(currentProgress / oneBlockPercent);
            const blockPercentLeft = currentProgress % oneBlockPercent;
            const leftScrollBorder = 0.3;
            const rightScrollBorder = 0.7;
            const centerBorder = 0.5;

            if (!isDesktop() || currentProgress === progressEnd || currentProgress === progressStart) {
                return;
            }

            if (
                blockPercentLeft > oneBlockPercent * leftScrollBorder &&
                blockPercentLeft < oneBlockPercent * centerBorder &&
                lastDirection === WheelDirection.down
            ) {
                currentBlockIndex = Math.min(elements.length, currentBlockIndex + 1);
            }

            if (
                blockPercentLeft > oneBlockPercent * centerBorder &&
                blockPercentLeft < oneBlockPercent * rightScrollBorder &&
                lastDirection === WheelDirection.up
            ) {
                currentBlockIndex = Math.max(1, currentBlockIndex - 1);
            }

            // @ts-ignore
            window.scroll({
                // @ts-ignore
                top: windowHeight + ((scrollHeight - windowHeight) / elements.length) * currentBlockIndex,
                // @ts-ignore
                behavior: window.Cypress ? undefined : 'smooth',
            });
        },
        [elements.length, getCurrentProgress],
    );

    const mouseWheel = useCallback(
        (event) => {
            const delay = (event as WheelEvent).deltaY;
            const direction = delay < 0 ? WheelDirection.up : WheelDirection.down;

            if (!isDesktop()) {
                return;
            }

            if (scrollTimeoutRef.current) {
                // @ts-ignore
                clearTimeout(scrollTimeoutRef.current);
            }

            // @ts-ignore
            scrollTimeoutRef.current = setTimeout(scrollToNearestBlock(direction), 2500);
        },
        [scrollToNearestBlock],
    );

    const scroll = useCallback(() => setProgress(getCurrentProgress()), [getCurrentProgress]);

    useMouseWheel(mouseWheel, wheelEventOptions);

    useScroll(scroll);

    useWindowResize(resize);

    return (
        <StyledContainer ref={containerRef} height={elements.length * progressEnd}>
            <StyledFixedWrap
                fixed={progress > progressStart && progress < progressEnd}
                bottom={progress === progressEnd}
            >
                {elements.map((child, index) =>
                    // @ts-ignore
                    React.cloneElement(child as ReactElement, {
                        // @ts-ignore
                        progress: isDesktop() ? getElementProgress(index) : progressEnd,
                        // @ts-ignore
                        totalProgress: isDesktop() ? progress : progressEnd,
                    }),
                )}
            </StyledFixedWrap>
        </StyledContainer>
    );
};
